import React from 'react';

import { styScrollWrapper } from './styles';
import { func } from 'prop-types';

function ClickToSeeDetail({ onClick }) {
  return (
    <div css={styScrollWrapper} onClick={onClick}>
      <section id="scroll" className="scroll__icon">
        <div className="button">
          <span></span>
        </div>
      </section>
    </div>
  );
}

ClickToSeeDetail.propTypes = {
  onClick: func.isRequired,
};

export default ClickToSeeDetail;
