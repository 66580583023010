import React from 'react';
import { string } from 'prop-types';

function WeddingInfoBox({ title, date, time, address, addressMapLink }) {
  return (
    <div className="col-md-4 col-sm-8 col-sm-offset-2 col-md-offset-0">
      <div className="event-wrap">
        <h3>{title}</h3>
        <div className="event-col">
          <i className="icon-clock"></i>
          <span>{time}</span>
        </div>
        <div className="event-col">
          <i className="icon-calendar"></i>
          <span>{date}</span>
        </div>
        {address && (
          <div className="event-col">
            <i className="icon-location2"></i>
            <span>
              <a href={addressMapLink} target="_blank" rel="noreferrer" title="Open Google Maps">
                {address}
              </a>
            </span>
          </div>
        )}
      </div>
    </div>
  );
}

WeddingInfoBox.propTypes = {
  title: string.isRequired,
  date: string.isRequired,
  time: string.isRequired,
  address: string.isRequired,
  addressMapLink: string.isRequired,
};

export default React.memo(WeddingInfoBox);
