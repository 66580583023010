import React, { Fragment } from 'react';
import { bool } from 'prop-types';
import { GOOGLE_MAPS_LINK } from '@/constants';
import { GOOGLE_MAPS_LINK_H } from '@/constants';

import WeddingInfoBox from './WeddingInfoBox';
import { styWrapper } from './styles';

function WeddingSection({ isInvitation }) {
  return (
    <Fragment>
      <div id="fh5co-event" css={styWrapper}>
        <div className="overlay" />
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2 text-center fh5co-heading">
              <h2 className="main-font main-font__wedding">Thời gian và địa điểm</h2>
              {/* <span className="sub-title sub-title__wedding">Insha Allah akan diselenggarakan pada:</span> */}
            </div>
          </div>
          <div className="row">
            <WeddingInfoBox
              title="Tiệc cưới nhà trai"
              time="Cả ngày"
              date="Chủ Nhật, 12 - 11 - 2023"
              address="Trịnh Xá 1, Yên Ninh, Yên Định, Thanh Hóa"
              addressMapLink={GOOGLE_MAPS_LINK}
            />
            <WeddingInfoBox
              title="Tiệc cưới nhà gái"
              time="Cả ngày"
              date="Chủ Nhật, 12 - 11 - 2023"
              address="An Khoái, Tứ Cường, Thanh Miện, Hải Dương"
              addressMapLink={GOOGLE_MAPS_LINK_H}
            />
            <WeddingInfoBox
              title="Lễ thành hôn"
              time="11:00"
              date="Thứ Hai, 13 - 11 - 2023"
              address="Trịnh Xá 1, Yên Ninh, Yên Định, Thanh Hóa"
              addressMapLink={GOOGLE_MAPS_LINK}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

WeddingSection.propTypes = {
  isInvitation: bool.isRequired,
};

export default React.memo(WeddingSection);
