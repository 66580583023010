import React, { useEffect } from 'react';
import { object, string, bool } from 'prop-types';

import WeddingImg from '@assets/images/wedding-logo.png';
import CountContainer from './CountContainer';
import ScrollToDown from './ScrollToDown';
import { styWrapper, styHero, styBackground } from './styles';

function WelcomeSection({ location, guestName, isInvitation, isAnonymGuest, codeLink }) {
  const handleScrollTo = () => {
    /** scroll into detail view */
    const element = document.getElementById('fh5co-couple');
    element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
  };

  const playMusic = () => {
    try {
      const musicEL = document.getElementById('musicEL');
      if (musicEL.currentTime) {
        return;
      }
      musicEL.play();
    } catch {
      console.error('FAILED_TO_PLAY_MUSIC');
    }
  };

  useEffect(() => {
    let timeoutId = null;
    const playMusicDelay = () => {
      timeoutId = setTimeout(playMusic, 1000);
      window.removeEventListener('scroll', playMusicDelay);
      document.body.removeEventListener('touchmove', playMusicDelay);
    };

    window.addEventListener('scroll', playMusicDelay);
    document.body.addEventListener('touchmove', playMusicDelay);

    return () => {
      timeoutId && clearTimeout(timeoutId);
    };
  }, []);

  const handleShowDetail = () => {
    handleScrollTo();
    playMusic();
  };

  return (
    <div css={styHero}>
      <header
        id="fh5co-header"
        role="banner"
        className="fh5co-cover"
        css={styBackground}
        data-stellar-background-ratio="0.5"
      >
        <div className="overlay"></div>
        <div className="container" style={{ marginTop: '280px' }}>
          <div className="row" css={styWrapper}>
            <div className="col-md-8 col-md-offset-2 text-center">
              <img src={WeddingImg} alt="wedding-tuan-huong" />
              <h4 className="sub-title">The Wedding of</h4>
              <h1 className="title">
                <span>Tuấn</span>
                <span>
                  <i className="icon-heart2"></i>
                </span>
                <span>Hương</span>
              </h1>
              <div className={isAnonymGuest ? 'margin__bottom' : ''}>
                <CountContainer />
              </div>
            </div>
          </div>
          <div className="row">
            <ScrollToDown onClick={handleShowDetail} />
          </div>
        </div>
      </header>
    </div>
  );
}

WelcomeSection.propTypes = {
  guestName: string.isRequired,
  isInvitation: bool.isRequired,
  isAnonymGuest: bool.isRequired,
  location: object.isRequired,
  codeLink: string,
};

WelcomeSection.defaultProps = {
  codeLink: '',
};

export default WelcomeSection;
