import { css } from '@emotion/core';
import React, { useEffect } from 'react';

const styleCalendarWrapper = css`
  margin-top: 30px;
  margin-bottom: 50px;
  font-size: 16px;
  font-family: 'Patrick Hand', mono;

  .table th,
  .table td,
  .table {
    text-align: center;
    border: none;
  }

  th,
  td {
    padding: 5px;
  }

  .calendar-base {
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.1);
    position: relative;
    color: rgb(80, 80, 80);
  }

  .grey {
    color: rgb(180, 180, 180);
  }

  .year {
    color: #9d82b2;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
  }

  .month-line {
    border-color: #e8e8e8;
    width: 93%;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .active-day {
    display: inline-block;
    border-radius: 50%;
    background-color: #9d82b2;
    position: relative;
    color: white;
    padding: 0 7px 0 6px;
    cursor: pointer;
  }

  /*.active-day::after {
    content: '';
    height: 5px;
    width: 5px;
    background-color: #2980B9;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    top: -17px;
    right: -2px;
  }*/

  .days-en {
    display: none;
  }

  @media screen and (max-width: 480px) {
    .days-en {
      display: table-row;
    }

    .days-vn {
      display: none;
    }
  }
`;

function Calendar() {
  useEffect(() => {
    const now = new Date();
    const today = `${now.getFullYear()}${String(now.getMonth() + 1).padStart(2, '0')}${String(now.getDate()).padStart(
      2,
      '0',
    )}`;

    const todayEl = document.querySelector(`td[data-date="${today}"]`);

    if (todayEl) {
      todayEl.style.display = 'inline-block';
      todayEl.style.backgroundColor = 'rgba(200, 200, 200, 0.5)';
      todayEl.style.position = 'relative';
      todayEl.style.padding = '3px 8px';
      todayEl.style.cursor = 'pointer';
      todayEl.title = 'Ngày hôm nay';
    }
  }, []);

  return (
    <div css={styleCalendarWrapper}>
      <div className="calendar-base">
        <div className="year">
          <i className="align-middle icon icon-heart2"></i> 11 - 2023 <i className="align-middle icon icon-heart2"></i>
        </div>

        <hr className="month-line" />

        <table className="table">
          <thead>
            <tr className="days-en">
              <th>SUN</th>
              <th>MON</th>
              <th>TUE</th>
              <th>WED</th>
              <th>THU</th>
              <th>FRI</th>
              <th>SAT</th>
            </tr>
            <tr className="days-vn">
              <th>Chủ Nhật</th>
              <th>Thứ Hai</th>
              <th>Thứ Ba</th>
              <th>Thứ Tư</th>
              <th>Thứ Năm</th>
              <th>Thứ Sáu</th>
              <th>Thứ Bảy</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td data-date="20231029" className="grey">
                29
              </td>
              <td data-date="20231030" className="grey">
                30
              </td>
              <td data-date="20231031" className="grey">
                31
              </td>
              <td data-date="20231101">01</td>
              <td data-date="20231102">02</td>
              <td data-date="20231103">03</td>
              <td data-date="20231104">04</td>
            </tr>
            <tr>
              <td data-date="20231105">05</td>
              <td data-date="20231106">06</td>
              <td data-date="20231107">07</td>
              <td data-date="20231108">08</td>
              <td data-date="20231109">09</td>
              <td data-date="20231110">10</td>
              <td data-date="20231111">11</td>
            </tr>
            <tr>
              <td data-date="20231112" title="Tiệc cưới">
                <strong className="active-day">12</strong>
              </td>
              <td data-date="20231113" title="Lễ thành hôn">
                <strong className="active-day">13</strong>
              </td>
              <td data-date="20231114">14</td>
              <td data-date="20231115">15</td>
              <td data-date="20231116">16</td>
              <td data-date="20231117">17</td>
              <td data-date="20231118">18</td>
            </tr>
            <tr>
              <td data-date="20231119">19</td>
              <td data-date="20231120">20</td>
              <td data-date="20231121">21</td>
              <td data-date="20231122">22</td>
              <td data-date="20231123">23</td>
              <td data-date="20231124">24</td>
              <td data-date="20231125">25</td>
            </tr>
            <tr>
              <td data-date="20231126">26</td>
              <td data-date="20231127">27</td>
              <td data-date="20231128">28</td>
              <td data-date="20231129">29</td>
              <td data-date="20231130">30</td>
              <td data-date="20231201" className="grey">
                01
              </td>
              <td data-date="20231202" className="grey">
                02
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

Calendar.propTypes = {};

export default React.memo(Calendar);
