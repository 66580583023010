import React, { Fragment, useState } from 'react';
import { bool } from 'prop-types';
import { css } from '@emotion/core';
import { styWrapper } from '../HelloSection/styles';

import Image from '@assets/images/DUC_8406_square.jpeg';
// import QRBank from '@assets/images/qr-bank2.png';
import QRBankCd from '@assets/images/VCB-Huong.jpg';
import QRBankCr from '@assets/images/VCB-Tuan.jpg';
import Calendar from './Calendar';

const styButtonWrapper = css`
  display: flex;
  justify-content: center;

  .button-nav.is-active,
  .button-nav:hover {
    background-color: #f14e95;
  }

  .button-nav {
    background-color: #9d82b2;
    color: #fff;
    font-size: 16px;
    padding: 6px 20px;
    margin-right: 10px;
  }

  .button-nav:last-child {
    margin-right: 0;
  }

  @media screen and (max-width: 420px) {
    & {
      display: block;
    }

    .button-nav {
      display: block;
      margin: 5px auto !important;
    }
  }
`;

function FooterSection({ isInvitation }) {
  const [qr, setQr] = useState(null);

  return (
    <Fragment>
      {!isInvitation && (
        <div id="fh5co-thank" css={styWrapper}>
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 text-center fh5co-heading">
                <h2 className="main-font">Lời cảm ơn</h2>
                <div className="couple-half" style={{ float: 'none', width: '100%' }}>
                  <div className="groom" style={{ float: 'none', margin: '0 auto' }}>
                    <img src={Image} alt="groom" className="img-responsive" loading="lazy" />
                  </div>
                </div>
                <p className="info">
                  Xin chân thành cảm ơn anh chị, bạn bè đã sắp xếp thời gian đến chung vui cùng gia đình em. Sự hiện
                  diện của mọi người là món quà lớn nhất của chúng em!
                </p>
                <p className="info">
                  Mọi món quà mừng hạnh phúc từ xa có thể thông qua mã QR bên dưới, chúng em xin ghi nhận và cảm ơn rất
                  nhiều!
                </p>
                <div css={styButtonWrapper}>
                  <button
                    className={`btn btn-sm button-nav ${qr === 'cr' ? 'is-active' : ''}`}
                    onClick={() => setQr(qr === 'cr' ? '' : 'cr')}
                  >
                    <i className="icon icon-mail"></i> Mừng cưới chú rể
                  </button>
                  <button
                    className={`btn btn-sm button-nav ${qr === 'cd' ? 'is-active' : ''}`}
                    onClick={() => setQr(qr === 'cd' ? '' : 'cd')}
                  >
                    Mừng cưới cô dâu <i className="icon icon-mail"></i>
                  </button>
                </div>
              </div>
            </div>
            {!!qr && (
              <div className="row">
                <div className="col-md-8 col-md-offset-2 text-center qr-bank">
                  <img src={qr === 'cd' ? QRBankCd : QRBankCr} alt="QR code" />
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      <footer className="fh5co-section-gray">
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2">
              <Calendar />
            </div>
          </div>
        </div>
      </footer>
    </Fragment>
  );
}

FooterSection.propTypes = {
  isInvitation: bool.isRequired,
};

export default React.memo(FooterSection);
